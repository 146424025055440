import './App.css';

function App() {
  return (
    <div className="App">
    <div className="annaiuncmentbar">
    

    </div>
      <header className="App-header">
      <img src="https://cdn.shopify.com/s/files/1/0728/2737/1743/files/shopifytaskernewlogodesign-removebg-preview.png?v=1731477862" alt="shopifytaskerlogo" style={{ width: "250px", height: "auto" }}   border="0"/>
    <h1> Hire Top Shopify Developer <br /> From shopifytasker</h1>
    <h1> 10 years experience in shopify ecosystem</h1>

  <h2>Accepting clients for Q4 2024 </h2>
       


      </header>
    </div>
  );
}

export default App;
